
import { Component, Vue } from 'vue-property-decorator';
const CALENDLY_STEFFEN = 'https://calendly.com/mieszko/demo';
const CALENDLY_MIESZKO = 'https://calendly.com/mieszko/demo';

@Component({
    components: {},
})
export default class BaseDemoDescription extends Vue {
    calendlySteffen = CALENDLY_STEFFEN;
    calendlyMieszko = CALENDLY_MIESZKO;
}
