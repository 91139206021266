
import { BButton, BCard } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ALT_TEXT } from '@thxnetwork/public/utils/constants';

@Component({
    components: {
        'b-card': BCard,
        'b-button': BButton,
    },
})
export default class BaseTestimonials extends Vue {
    @Prop() home!: boolean;
    ALT_TEXT = ALT_TEXT;
}
