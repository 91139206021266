
import { BButton, BJumbotron, BFormInput } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';

const Calendly = (window as any).Calendly;
const CALENDLY_STEFFEN = 'https://calendly.com/mieszko/demo';
const CALENDLY_MIESZKO = 'https://calendly.com/mieszko/demo';

@Component({
    components: {
        BJumbotron,
        BButton,
        BFormInput,
    },
})
export default class BaseJumbotronDemo extends Vue {
    selectedCalendly = false;

    mounted() {
        this.updateCalendly();
    }

    get calendlyURL() {
        return this.selectedCalendly ? CALENDLY_STEFFEN : CALENDLY_MIESZKO;
    }

    updateCalendly() {
        (this.$refs.widgetCalendly as Element).innerHTML = '';

        Calendly.initInlineWidget({
            url: `${this.calendlyURL}?text_color=f8f9fa&background_color=212529&primary_color=ffe500&hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1`,
            parentElement: this.$refs.widgetCalendly,
            prefill: {},
            utm: {},
        });
    }
}
